<template>
  <b-container class="container_product_information" fluid>
    <b-row>
      <b-col cols="12" lg="6" class="container_product_information__text" order="2" order-lg="1">
        <h5 class="mb-4">Why was the app made?</h5>
        <h3 class="mb-4">Understanding the importance of a healthy API for any application development process</h3>
        <p class="mb-lg-5">
          ApiMonitor24.com is designed to offer a complete but still economic solution to analyze the performance of any and each API endpoint and detect failures even before they reach the end user.
        </p>
      </b-col>
      <b-col cols="12" lg="6" class="container_product_information__media mb-5 mb-lg-0" align-self="center" order="1" order-lg="2">
        <img src="@/assets/img/man-pc.png" class="img-fluid"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    
  }
</script>
