<template>
  <div class="container_monitoring">
    <b-img :src="require('@/assets/img/Sign Up-2.png')" fluid></b-img>
    <b-img :src="require('@/assets/img/desk.png')" fluid></b-img>
  </div>
</template>

<script>
  export default { }
</script>
