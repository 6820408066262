<template>
  <div>
    <Navbar/>
    <Plans/>
    <ProductInformation/>
    <Referrals/>
    <Monitoring/>
    <SignUp/>
    <Footer class="d-none d-xl-block"/>
    <Footer2 class="d-xl-none"/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Plans from '@/views/Home/Plans.vue';
import ProductInformation from '@/views/Home/ProductInformation.vue';
import Referrals from '@/views/Home/Referrals.vue';
import Monitoring from '@/views/Home/Monitoring.vue';
import SignUp from '@/views/Home/SignUp.vue';
import Footer from '@/components/Footer.vue';
import Footer2 from '@/components/Footer2.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    Plans,
    ProductInformation,
    Referrals,
    Monitoring,
    SignUp,
    Footer,
    Footer2
  },
}
</script>
