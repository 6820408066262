<template>
  <b-container fluid class="container_plans">
    <b-row class="container_plans__text py-4 py-md-0 mb-md-0">
      <b-col cols="12" class="px-4 mt-2">
        <h3 class="text-center text-white fw-bolder mt-2">
          MULTI-STEP REAL TIME API MONITORING
        </h3>
        <h3 class="text-center text-white fw-bolder mb-3">
          All you need for reliable mobile APP monitoring
        </h3>
        <p class="text-center text-white mb-4">
          Know exactly whenyour API services break or send a bad response before your mobile app users start
          complaining. Our intelligent API monitoring service does not just tell you when your API is not working,
          it tells you if the API service is not sending the expected result.
        </p>
      </b-col>
      <b-col cols="12" class="mb-4">
        <h3 class="text-center text-white mt-2 mb-3 mb-md-5 choose">
          CHOOSE YOUR PLAN
        </h3>
      </b-col>
    </b-row>
    <b-row class="container_plans__cards">
      <!-- Basic -->
      <b-col cols="12" md="4" lg="4" align-self="center" class="px-4">
        <b-card no-body class="py-5 px-4 card-basic">
          <h5 class="text-center fw-bolder mb-2 mb-md-4">BASIC PLAN</h5>
          <div class="generic_price_tag text-center mb-md-2">
            <span class="price">
              <span class="sign">$</span>
              <span class="currency">2</span>
              <span class="cent">.99</span>
              <span class="month">Month</span>
            </span>
          </div>
          <p class="text-center">
            1 API Monitor <br>
            - Up to 10 API services <br>
            - 1 Base URL <br>
            - Unlimited WhatsApp Alerts <br>
            - Unlimited e-mail Alerts <br>
            - Full Service respond test <br>
            - Up to 3 Users to recieve alerts
          </p>
          <a href="#" class="text-center mb-4 text-decoration-none link-primary">Read more</a>
          <div class="text-center">
            <b-button variant="primary" class="px-4">Start now</b-button>
          </div>
        </b-card>
      </b-col>
      <!-- Standard -->
      <b-col cols="12" md="4" lg="4" align-self="center" class="px-4">
        <b-card no-body class="py-5 px-4 card-standard">
          <h5 class="text-center fw-bolder mb-2 mb-md-4">STANDARD PLAN</h5>
          <div class="generic_price_tag text-center mb-md-2">
            <span class="price">
              <span class="sign">$</span>
              <span class="currency">6</span>
              <span class="cent">.99</span>
              <span class="month">Month</span>
            </span>
          </div>
          <p class="text-center">
            3 API Monitors <br>
          - Up to 30 API services <br>
          - Up to 3 different Base URL <br>
          - Unlimited WhatsApp Alerts <br>
          - Unlimited e-mail Alerts <br>
          - Full Service respond test <br>
          - Up to 10 Users to recieve alerts <br>
          From 1 Minute to up to 10 minute schedules <br>
          - Evaluate the sintax of your API response <br>
          </p>
          <a href="#" class="text-center mb-4 text-decoration-none link-primary">Read more</a>
          <div class="text-center">
            <b-button variant="primary" class="px-4">Start now</b-button>
          </div>
        </b-card>
      </b-col>
      <!-- Business -->
      <b-col cols="12" md="4" lg="4" align-self="center" class="px-4">
        <b-card no-body class="py-5 px-4 mb-0 card-business">
          <h5 class="text-center fw-bolder mb-2 mb-md-4">BUSINESS PLAN</h5>
          <div class="generic_price_tag text-center mb-md-2">
            <span class="price">
              <span class="sign">$</span>
              <span class="currency">19</span>
              <span class="cent">.99</span>
              <span class="month">Month</span>
            </span>
          </div>
          <p class="text-center">
            10 API Monitors <br>
            - Up to 100 API services <br>
            - Up to 10 different Base URL <br>
            - Unlimited WhatsApp Alerts <br>
            - Unlimited e-mail Alerts <br>
            - Full Service respond test <br>
            - Up to 35 Users to recieve alerts <br>
          </p>
          <a href="#" class="text-center mb-4 text-decoration-none link-primary">Read more</a>
          <div class="text-center">
            <b-button variant="primary" class="px-4">Start now</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    
  }
</script>
