<template>
  <b-container class="container_sign_up_home" fluid>
    <b-row class="h-100">
      <b-col align-self="center" class="container_sign_up_home__card mt-3 mt-md-0"
        order="2" order-md="1" cols="12" md="6">
        <form @submit.prevent="sendForm">
          <b-card no-body class="mx-auto">
            <h4 class="text-center mb-4 fw-bold">Sign Up</h4>
            <!-- First name -->
            <b-form-group class="mb-3">
              <b-form-input type="text" placeholder="First name"
                :class="{ 'is-invalid': $v.form.first_name.$error }"
                v-model.trim="$v.form.first_name.$model"></b-form-input>

              <span role="alert" class="invalid-feedback" v-if="$v.form.first_name.$error">
                <span v-if="!$v.form.first_name.required">The First name is required</span>
                <span v-if="!$v.form.first_name.minLength">
                  The First name must have at least
                  {{ $v.form.first_name.$params.minLength.min }} characters
                </span>
                <span v-if="!$v.form.first_name.maxLength">
                  The First name must be less than
                  {{ $v.form.first_name.$params.maxLength.max }} characters
                </span>
              </span>
            </b-form-group>

            <!-- Email -->
            <b-form-group class="mb-3">
              <b-form-input type="email" placeholder="Email"
                :class="{ 'is-invalid': $v.form.email.$error }"
                v-model.trim="$v.form.email.$model"></b-form-input>

              <span role="alert" class="invalid-feedback">
                <span v-if="!$v.form.email.required">The Email is required</span>
                <span v-if="!$v.form.email.email">Invalid Email format</span>
                <span v-if="!$v.form.email.maxLength">
                  The Email must be less than
                  {{ $v.form.email.$params.maxLength.max }} letters
                </span>
              </span>
            </b-form-group>
            <b-button variant="primary" type="submit">Continue registration</b-button>
          </b-card>
        </form>
      </b-col>
      <b-col align-self="center" class="container_sign_up_home__text"
        order="1" order-md="2" cols="12" md="6">
        <h2 class="text-center text-md-start mb-4 mb-md-0">
          It has survived not only five centuries
        </h2>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        first_name: String(),
        email: String(),
      },
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60),
      },
      email: { required, email, maxLength: maxLength(60) },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.$router.push({
          name: "register",
          query: { name: this.form.first_name, email: this.form.email },
        });
      }
    },
  },
};
</script>
