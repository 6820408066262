<template>
  <b-container class="container_referrals" fluid>
    <b-row align-h="center">
      <b-col cols="12" lg="10">
        <h2 class="fw-bold mb-4">Refer a friend and get 3 FREE Months!</h2>
        <div class="email mb-3">
          <div class="input-container">
            <div class="letter d-flex">
              <b-img :src="require('@/assets/img/letter.png')" fluid></b-img>
            </div>
            <b-form-input placeholder="Email"></b-form-input>
          </div>
        </div>
        <b-button class="px-5">Send</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    
  }
</script>
